import { Maybe } from 'yup/lib/types';
import { GaitSessionReport } from '@/common/types';

export const getSpeedFromSessionData = (session: GaitSessionReport): Maybe<string> => {
    const speedData = session.session.data.find((d) => {
        return d.item === 'speed';
    });
    if (!speedData) {
        return;
    }
    const speedResult = speedData.measures.find((m) => {
        return m.name === 'avgSpeed' || m.name === 'speed';
    });
    if (!speedResult) {
        return;
    }
    return speedResult.value.toFixed(2);
};
