import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DateTime } from 'luxon';
import { GaitSessionReport } from '@/common/types';
import { getSpeedFromSessionData } from './gaitReportUtils';
import { GaitReportDownloadButtonWrapper, GaitReportDownloadButton } from './styles';

interface GaitReportChartProps {
    sessions: GaitSessionReport[];
    startEnd: Date;
}

export const GaitReportChart = (props: GaitReportChartProps) => {
    const { sessions, startEnd } = props;
    const [chartInstance, setChartInstance] = useState<ChartJS<'bar', string[], string> | null>(null);
    const gaitEventChartRef = useRef<HTMLCanvasElement>(null);
    const imgDownloadRef = useRef<HTMLAnchorElement>(null);

    const customCanvasBackgroundColor = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
            const { ctx } = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color || '#99ffff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        },
    };

    const generateImage = useCallback(() => {
        if (chartInstance && imgDownloadRef.current) {
            const url = chartInstance.toBase64Image();
            imgDownloadRef.current.href = url;
        }
    }, [chartInstance]);

    useEffect(() => {
        ChartJS.register(annotationPlugin);
        if (!gaitEventChartRef?.current) return;
        const chartDict: { [key: string]: string } = { go: '30' };
        const ctx = gaitEventChartRef.current.getContext('2d');
        if (ctx) {
            const newChartInstance = new ChartJS(ctx, {
                type: 'bar',
                plugins: [customCanvasBackgroundColor],
                data: {
                    labels: Object.keys(chartDict),
                    datasets: [
                        {
                            label: 'Speed',
                            data: Object.values(chartDict),
                        },
                    ],
                },
                options: {
                    responsive: true,
                    layout: {
                        padding: 20,
                    },
                    plugins: {
                        customCanvasBackgroundColor: {
                            color: '#ffffff',
                        },
                        annotation: {
                            annotations: {
                                line1: {
                                    type: 'line',
                                    yMin: 0.8,
                                    yMax: 0.8,
                                    borderColor: 'rgb(255, 99, 132)',
                                    borderWidth: 1,
                                    borderDash: [2, 4],
                                },
                            },
                        },
                    },
                },
            });
            setChartInstance(newChartInstance);
        }
    }, []);

    useEffect(() => {
        if (chartInstance) {
            let lowerCount = 0;
            let highestSpeed = 0;
            const chartDict: { [key: string]: string } = {};
            sessions?.forEach((session) => {
                if (session.subject.gaitUid === 'test') {
                    return;
                }
                const speed = getSpeedFromSessionData(session);
                if (!speed) {
                    return;
                }
                if (+speed < 0.8) {
                    lowerCount++;
                }
                if (+speed > highestSpeed) {
                    highestSpeed = +speed;
                }
                chartDict[session.subject.gaitUid || session.subject.lastName] = speed;
            });

            chartInstance.data.datasets = [
                {
                    label: 'Gait Speed (m/s)',
                    data: Object.values(chartDict),
                    backgroundColor: 'rgba(29, 132, 233, 0.6)',
                },
            ];
            chartInstance.data.labels = Object.keys(chartDict);
            chartInstance.options.animation = {
                onComplete: generateImage,
            };
            chartInstance.options.plugins = {
                ...chartInstance.options.plugins,
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: 0.8,
                            yMax: 0.8,
                            borderColor: '#ed473a',
                            borderWidth: 2,
                            borderDash: [2, 4],
                        },
                        label1: {
                            type: 'label',
                            xMax: 0.25,
                            yMin: highestSpeed,
                            padding: 8,
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: '#ed473a',
                            content: [` ${((lowerCount / sessions.length) * 100).toFixed(0)}% below 0.8 m/s `],
                            font: {
                                size: 18,
                            },
                        },
                    },
                },
            };
            chartInstance.update();
        }
    }, [chartInstance, generateImage, sessions]);

    return (
        <div>
            <GaitReportDownloadButtonWrapper>
                <GaitReportDownloadButton onClick={generateImage}>
                    <a
                        ref={imgDownloadRef}
                        download={`gait_Event${DateTime.fromJSDate(new Date(startEnd)).toFormat('MM-dd-yyyy')}.png`}
                    >
                        Download Chart Image
                    </a>
                </GaitReportDownloadButton>
            </GaitReportDownloadButtonWrapper>

            <canvas ref={gaitEventChartRef} id="gait-event-chart" />
        </div>
    );
};
