import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { TableOptions, useTable } from 'react-table';
import { NoPatients } from '@/components/Patients/NoPatients';
import { PatientsTableWrapper } from '@/components/common/Table/PatientsTable/styles';
import { TableWrapper, FullWidthTable, TableHeader, TableHeaderCell } from '@/components/common/Table/styles';
import { GaitReportParticipantTableRow } from './GaitReportParticipantTableContainer';

interface GaitReportParticipantTableProps {
    columnStructure: any[];
    data: GaitReportParticipantTableRow[];
}

export const GaitReportParticipantTable = (props: GaitReportParticipantTableProps) => {
    const { columnStructure, data } = props;
    const options: TableOptions<GaitReportParticipantTableRow> = {
        data,
        columns: columnStructure,
    };
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(options);

    return (
        <PatientsTableWrapper>
            <TableWrapper>
                <FullWidthTable {...getTableProps()}>
                    <TableHeader>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={headerGroup.id + '-' + headerGroup.Cell?.toString}
                            >
                                {headerGroup.headers.map((column) => {
                                    const headerProps = { ...column.getHeaderProps() };
                                    return (
                                        <TableHeaderCell
                                            {...headerProps}
                                            $isSorted={false}
                                            key={column.id}
                                            style={{
                                                width: column.width,
                                                maxWidth: column.width,
                                                minWidth: column.width,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {column.render('Header')}
                                        </TableHeaderCell>
                                    );
                                })}
                            </tr>
                        ))}
                    </TableHeader>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()} key={row.original.SESSION_UUID}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={cell.column.id}
                                                style={{
                                                    width: cell.column.width,
                                                    maxWidth: cell.column.width,
                                                    minWidth: cell.column.width,
                                                }}
                                                size="small"
                                            >
                                                {cell.render('Cell')}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </tbody>
                </FullWidthTable>
            </TableWrapper>
            {rows.length === 0 && <NoPatients />}
        </PatientsTableWrapper>
    );
};
