import { ReactNode } from 'react';
import { ExerUser } from '@exerai/react-core';
import { DateTime } from 'luxon';
import {
    BodyPart,
    ExerciseSessionTypes,
    HealthPatientStatus,
    Products,
    SideOfBody,
    DashboardActions,
    DashboardActionStatus,
    MetricCode,
    Involvedness,
    RtmCode,
    RtmCycleStatus,
    RtmEvent,
    PATIENT_STATUS,
    SelfReportedEnum,
    ScanResultsSchema,
} from './const';
import { getStringEnumKeyByValue } from './utils';

export interface Action {
    type: string;
    payload?: any;
}

export type ROLES = 'UNKNOWN' | 'PORTAL_PROVIDER_ADMIN' | 'PORTAL_PRACTITIONER' | 'EXER_ADMIN';

export type ProductKey = keyof typeof Products;
/* Profile Properties */

export enum ProfileProperites {
    ORGANIZATION_ID = 'organizationId',
    ORGANIZATION_NAME = 'organizationName',
    ORGANIZATION_TIMEZONE = 'organizationTimezone',
    ORGANIZATION_LOGO_URL = 'organizationLogoUrl',
    PRODUCTS = 'products',
    LOCATIONS = 'locations',
}

export interface ExerProviderUser extends ExerUser {
    organizationId: number;
    organizationName: string;
    organizationTimezone: string;
    organizationLogoUrl?: string;
    groups: ROLES[];
    products?: string[];
    locations: UserLocation[];
}

/* End Profile Properties */

export interface CreateOrganizationDto {
    orgName: string;
    practitioner: {
        firstName: string;
        lastName: string;
        email: string | null;
        role: string;
    };
    timezone: string;
    products: string[];
    location: Omit<AdminLocation, 'organization'>;
}

export interface UpdateOrganizationDto {
    name: string;
}

export interface UpdateOrganizationAdminDto {
    name?: string;
    scanModules?: Nullable<ScanModule[]>;
}

export interface Organization {
    id: number;
    name: string;
    products: string[];
    timezone: string;
    logoUrl: string;
    webLogoUrl: string;
}

export interface OrganizationAdmin extends Organization {
    scanModules: Nullable<ScanModule[]>;
}

export interface UserProfile {
    id: number;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    organization?: Organization;
    locations: UserLocation[];
}

export interface UserProfileWithInviteCode {
    firstName: string;
    lastName: string;
    email: string;
    inviteCode: string;
    hasAccount: boolean;
}

export interface Patient {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    dob?: string | null;
    hasAccount?: boolean;
    discharged?: boolean;
}

export type PractitionerShort = Omit<
    Practitioner,
    'email' | 'inviteCode' | 'userId' | 'role' | 'addedAsRole' | 'locations'
>;

export interface Location {
    id?: number;
    name: string;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    stateCode?: string | null;
    zipCode?: string | null;
    countryCode?: string | null;
    defaultProviderId?: number;
    defaultProvider?: PractitionerShort;
}

export type AdminOrganization = Omit<Organization, 'products' | 'timezone' | 'logoUrl'>;

export interface AdminLocation extends Location {
    keyword?: string | null;
    contactList?: string | null;
    organization: AdminOrganization;
}

export interface UserLocation {
    id: number;
    name: string;
}

export type CreateLocationDto = Omit<Location, 'id' | 'defaultProvider'>;

export interface Practitioner {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    inviteCode?: string;
    userId?: string;
    role?: ROLES;
    addedAsRole?: ROLES;
    locations: UserLocation[];
}

export type CreatePractitionerDto = Omit<Practitioner, 'id' | 'locations'> & { role: ROLES; locationIds: number[] };
export type UpdatePractitionerDto = Practitioner & { role: ROLES; locationIds: number[] };

export type PractitionerSlim = Omit<Practitioner, 'email' | 'userId' | 'role' | 'addedAsRole'>;

export interface PractitionerOption {
    id: number;
    name: string;
}

export type HealthPatientStatusKey = keyof typeof HealthPatientStatus;

export interface PatientRecordProductsData {
    health?: {
        injuredSideOfBody: string;
        injuredBodyPart: string;
        status: HealthPatientStatusKey;
        heps: Program[] | HEPSummary[];
        mostRecentSession: string;
        mostRecentActivity: string;
    };
    gait?: {
        mostRecentSession: string;
        uid: string | null;
    };
}

export interface PatientRecord {
    id: number;
    patient: Patient;
    practitioner?: PractitionerSlim;
    location?: Location;
    discharged?: boolean;
    products: string[];
    productData: PatientRecordProductsData;
    mostRecentSession: string;
    mostRecentActivity: string;
    plansOfCare: PlanOfCare[];
}

export interface PlanOfCare {
    start: string | null;
    end: string | null;
}

export enum GoalType {
    REPS = 'REPS',
    TIME = 'TIME',
    ROM = 'ROM',
    REPS_OR_TIME = 'REPS_OR_TIME',
}

export const isRepsGoal = (goal: SetGoal): goal is RepsGoal => {
    return (goal as RepsGoal).reps !== undefined;
};

export interface ROMGoal {
    time: number;
    goalType: GoalType.ROM;
}

export interface RepsGoal {
    reps: number;
    goalType: GoalType.REPS;
}
export const isTimeGoal = (goal: SetGoal): goal is TimeGoal => {
    return (goal as TimeGoal).time !== undefined;
};

export interface TimeGoal {
    time: number;
    goalType: GoalType.TIME;
}

export const isRepsOrTimeGoal = (exerciseId: number) => {
    // Hardcoding to Sit to Stand ID, until new Exercise Metric is added
    return [266].includes(exerciseId);
};

export interface RepsOrTimeGoal {
    reps: number;
    time: number;
    goalType: GoalType.REPS_OR_TIME;
}

export type SetGoal = RepsGoal | TimeGoal | RepsOrTimeGoal | ROMGoal;

export enum PROMessageType {
    PON = 'PERCENTAGE_OF_NORMAL',
    LOP = 'LEVEL_OF_PAIN',
}

export interface DateOptions {
    seven: Date;
    thirty: Date;
    ninety: Date;
}

export interface TimeSpan {
    startDate: Date;
    endDate: Date;
}

type RomLimit = {
    label: string;
    limit: number;
};
type StandardSettings = any;

type SimpleRomSettings = {
    min: RomLimit;
    max: RomLimit;
    minMaxLabel: string;
};

type MetricSpecificSettings = {
    [key in MetricCode]?: SimpleRomSettings | StandardSettings;
};
type GlobalSettings = {
    GLOBAL?: any;
};
type ExerciseSettingsJson = MetricSpecificSettings & GlobalSettings;

export interface Exercise {
    id: string;
    name: string;
    simpleName: string;
    exerciseType: string;
    bodyTargets: string[];
    action: string;
    position: string;
    cameraHeight: 'TABLE' | 'FLOOR';
    cameraOrientation: 'LANDSCAPE' | 'PORTRAIT';
    subjectOrientation: 'FRONT' | 'SIDE' | 'BACK';
    subjectPosition: 'STANDING' | 'PRONE' | 'SIDE_LYING' | 'SUPINE' | 'SEATED' | 'QUADRUPED' | 'KNEELING';
    equipment: 'THERABAND' | 'WEIGHTS' | 'TOWEL' | 'CANE' | 'CHAIR' | 'PHYSIOBALL';
    sequence: 'SINGLE' | 'BILATERAL' | 'ALTERNATING';
    withAnchorPoint: boolean;
    withHold: boolean;
    activeAssisted: boolean;
    settings: ExerciseSettingsJson;
    thumbnailUrl: string;
    demoUrl: string;
    demoAudioUrl: string | null;
    animationUrl: string | null;
    animationThumbnailFrame: number | null;
    exerciseMetrics: ExerciseMetricOmitExercise[];
}

export type ExerciseOmitMetrics = Omit<Exercise, 'exerciseMetrics'>;

export interface ExerciseMetric {
    id: number;
    metric: keyof typeof MetricCode;
    exercise: ExerciseOmitMetrics;
    settings: object | null;
}

export type ExerciseMetricOmitExercise = Omit<ExerciseMetric, 'exercise'>;

export interface ROMExerciseMetric extends ExerciseMetric {
    settings: { ROM: SimpleRomSettings } | null;
}

export const isROMExerciseMetric = (
    exerciseMetric: ExerciseMetric | ExerciseMetricOmitExercise | ExerciseMetricSummary,
): exerciseMetric is ROMExerciseMetric => {
    if (!exerciseMetric) return false;
    if (exerciseMetric.metric === getStringEnumKeyByValue(MetricCode, MetricCode.ROM)) {
        return true;
    } else {
        return false;
    }
};

export const isRepExerciseMetric = (
    exerciseMetric: ExerciseMetric | ExerciseMetricOmitExercise | ExerciseMetricSummary,
): exerciseMetric is ExerciseMetric => {
    if (exerciseMetric.metric === getStringEnumKeyByValue(MetricCode, MetricCode.REPS)) {
        return true;
    } else {
        return false;
    }
};

export const isTimeExerciseMetric = (
    exerciseMetric: ExerciseMetric | ExerciseMetricOmitExercise | ExerciseMetricSummary,
): exerciseMetric is ExerciseMetric => {
    if (exerciseMetric.metric === getStringEnumKeyByValue(MetricCode, MetricCode.TIME)) {
        return true;
    } else {
        return false;
    }
};

export const isROMExerciseMetricSummary = (
    exerciseMetric: ExerciseMetricSummary,
): exerciseMetric is ROMExerciseMetricSummary => {
    if (!exerciseMetric || !('romProperties' in exerciseMetric) || exerciseMetric.romProperties === undefined) {
        return false;
    }
    return true;
};

export interface ROMExerciseMetricSummary extends ExerciseMetricSummary {
    romProperties: SimpleRomSettings;
}

export interface ExerciseMetricSummary {
    id: number;
    name: string;
    metric: keyof typeof MetricCode;
    thumbnailUrl: string;
    animationUrl: string | null;
    exerciseType: string;
    romProperties?: SimpleRomSettings;
    animationJson: { op: number } | null;
    animationThumbnailFrame: number | null;
    action: string;
    position: string;
}

export interface ExercisesDict {
    [id: number]: ExerciseMetricSummary;
}

export interface HEPBlock {
    id?: number | string;
    // TODO This should be exerciseMetricId. on the server as well
    exerciseId: number;
    numSets: number;
    sideOfBody?: keyof typeof SideOfBody;
    setGoal?: SetGoal;
    uuid?: string;
}
export interface HEPSummary {
    id: number;
    name: string;
}

export interface Program {
    id: number;
    name: string;
    notes?: string;
    weeklyFrequency: number;
    dailyFrequency: number;
    blocks: HEPBlock[];
}

export interface HEPTemplate extends Program {
    dateUpdated: string;
}

export type CreateHEPDto = Omit<Program, 'id'>;

export interface OrganizationHEP extends Program {
    patientCount: number;
    dateCreated: Date;
}

export interface HEPAssignment {
    id: number;
    patientRecord: PatientRecord;
    hep?: Program;
    dateAssigned: Date;
    assignedBy: Practitioner;
}

export interface HealthPatientRecord {
    id: number;
    patient: Patient;
    location?: Location;
    mostRecentSession: string;
    injuredSideOfBody: SideOfBody;
    injuredBodyPart: BodyPart;
    heps: HEPSummary[];
    discharged?: boolean;
    organization: Organization;
}

export interface GaitSessionMeta {
    assistanceOption: 'CANE' | 'WALKER' | 'WHEELCHAIR' | 'OTHER' | 'NONE';
    capturedDate: string; // in ISO 8601 date format
    comment?: string;
}

export type GaitSessionVersionSchema = 'GAIT-SA-1' | 'GAIT-SA-2' | 'GAIT-FA-1' | 'GAIT-FA-2';
export type GaitSessionDataType = 'gait' | 'bodypart';
export type GaitSessionDataMeasuresUnit = '°' | '%' | 'm/s' | 'm' | 's';
export type GaitSessionV1DataItem = 'speed' | 'time' | 'trunk' | 'hip';
export type GaitSessionV2DataItem = 'speed' | 'trunk' | 'hip' | 'knee' | 'arm' | 'ankle' | 'head' | 'step' | 'stride';
export type GaitSessionV1DataName = 'speed' | 'time' | 'maxTrunkAngle' | 'hipFlexionAngle' | 'hipFlexionAngleYAxis';

export type GaitSessionV2DataName =
    | 'rightFlexion'
    | 'leftFlexion'
    | 'rightExtension'
    | 'leftExtension'
    | 'flexionDifference'
    | 'extensionDifference'
    | 'maxTrunkAngle'
    | 'maxHeadFlexionAngle'
    | 'leftStep'
    | 'rightStep'
    | 'stepDifference'
    | 'leftStride'
    | 'rightStride'
    | 'strideDifference'
    | 'avgSpeed'
    | 'lapOneTime'
    | 'lapTwoTime'
    | 'avgTime'
    | 'distance'
    | 'rightDorsiflexion'
    | 'rightPlantarFlexion'
    | 'leftDorsiflexion'
    | 'leftPlantarFlexion';

export interface GaitSessionDataMeasures {
    name: GaitSessionV1DataName | GaitSessionV2DataName;
    value: number;
    displayName: string;
    unit: GaitSessionDataMeasuresUnit;
}

export interface GaitSessionData {
    item: GaitSessionV1DataItem | GaitSessionV2DataItem;
    measures: GaitSessionDataMeasures[];
    type: GaitSessionDataType;
}

export interface GaitSession {
    sessionUUID: string;
    sessionSchema: GaitSessionVersionSchema;
    session: {
        data: GaitSessionData[];
        meta: GaitSessionMeta;
    };
}

export interface GaitSessionReport extends GaitSession {
    subject: { firstName: string; lastName: string; gaitUid: string };
}

export interface ThresholdFunction {
    (param: number): boolean;
}
export interface PatientsTableRow {
    NAME: string;
    LAST_ACTIVITY: string | Date;
    LOCATION?: string;
    id: number;
    EMAIL: string;
    STATUS?: HealthPatientStatusKey | null;
    program?: string;
    patientRecord: PatientRecord;
}

export interface GaitSessionTableRow {
    assessmentType: ReactNode;
    average: ReactNode;
    percentDifferenceOfAverage: ReactNode;
    maximum: ReactNode;
}

export interface GaitSessionFooterMeasurements {
    distance: ReactNode;
    avgTime: ReactNode;
    lapOneTime: ReactNode;
    lapTwoTime: ReactNode;
}

export interface CreateUpdatePatientDto extends Patient {
    locationId?: number;
    products: string[];
    primaryPractitionerId?: number;
    location?: Location;
    injuredSideOfBody?: string | null;
    gaitUid?: string | null;
}

export interface UpdateProfile {
    userId: string;
    firstName: string;
    lastName: string;
}

export interface ErrorCount {
    message: string;
    count: number;
}

export interface ErrorCountDict {
    [key: string]: ErrorCount;
}

export enum SetResolution {
    NOT_STARTED = 'Not Started',
    SKIPPED = 'Skipped',
    SKIPPED_IN_SET = 'Skipped in Set',
    CALIBRATION_ERROR = 'Calibration Error',
    SUCCESS = 'Success',
    SELF_REPORTED = 'Self Reported',
}

export type SetResolutionKeys = keyof typeof SetResolution;

export interface SetInfo {
    repsGoal?: number /* int */;
    repsComplete?: number /* int */;
    repsProper?: number /* int */;
    repTotalTime?: number /* float */;
    timeGoal?: number /* int */;
    timeComplete?: number /* int */;
    minROM?: number /* float */;
    maxROM?: number /* float */;
    errors?: ErrorCountDict;
    skipped?: boolean;
    resolution?: SetResolutionKeys;
    completedDate: string;
    sideOfBody?: keyof typeof SideOfBody;
    involvedness?: keyof typeof Involvedness;
    reportingMetric?: ReportingMetric;
}

export enum ReportingMetric {
    ROM = 'ROM',
    REPS = 'REPS',
    TIME = 'TIME',
    REPS_OR_TIME = 'REPS_OR_TIME',
}

export interface RawSessionExercise {
    id: number;
    name?: string;
}

export enum MinMaxLabel {
    MIN_MAX = 'MIN_MAX',
    START_RANGE_END_RANGE = 'START_RANGE_END_RANGE',
    FLEXION_EXTENSION = 'FLEXION_EXTENSION',
    INTERNAL_EXTERNAL = 'INTERNAL_EXTERNAL',
}

export interface SessionExercise {
    id: number;
    name: string;
    minMaxLabel: MinMaxLabel;
    min: {
        label: string;
    };
    max: {
        label: string;
    };
}

export interface RawExerciseSetInfo {
    exercise: RawSessionExercise;
    setInfo: SetInfo;
}

export interface ExerciseSetInfo extends RawExerciseSetInfo {
    exercise: SessionExercise;
}

export interface ExerciseGroupedSet {
    name: string;
    sets: ExerciseSetInfo[];
}

export interface BlockGroupedSet {
    id: string | number;
    exercise: ExerciseGroupedSet;
}

export interface BlockGroupedSetBySide {
    id: string;
    exercise: ExerciseGroupedSet;
}

export interface CSVGroupedSet {
    name: string;
    sets: ExerciseSetInfo[];
    isMin?: boolean;
    isMax?: boolean;
}

export interface RawExerciseSession {
    sets: RawExerciseSetInfo[];
    type: ExerciseSessionTypes;
    hepId: number;
    hepName: string;
    selfReported: SelfReportedEnum;
}

export interface ExerciseSession extends RawExerciseSession {
    sets: ExerciseSetInfo[];
}

export interface RawSession {
    id?: number;
    user?: UserProfile;
    createdDate: string;
    updatedDate: string;
    session: RawExerciseSession;
    sessionUUID: string;
    created?: string;
    updated?: string;
}

export interface Session extends RawSession {
    session: ExerciseSession;
}

export type BlockSetMap = Map<number | string, BlockGroupedSet | BlockGroupedSetBySide>;

export interface SessionsByDateDict {
    [key: string]: Session[];
}

export interface GaitSessionsByDateDict {
    [key: string]: GaitSession[];
}

export interface ChartDataset {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
}

export interface PROChartDataset {
    label: string;
    data: string[];
    borderColor: string;
    backgroundColor: string;
}

export interface PaginationMeta {
    totalItems: number;
    itemCount: number;
    itemsForPage: number;
    totalPages: number;
    currentPage: number;
}

export type Paginated<T> = {
    items: T;
    metadata: PaginationMeta;
};

export interface PagedRequestParams {
    page?: number;
    limit?: number;
    search?: string;
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
}

export type PlainObject = { [name: string]: any };

export interface ChunkDataProps {
    data: object[];
    chunkSize: number;
}

export enum LoadingStatus {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum RTMStatusEnum {
    IN_PROGRESS = 'In Progress',
    ACTIVE_DAYS_INCOMPLETE = 'Incomplete',
    READY_TO_BILL = 'Ready to Bill',
    BILLED = 'Billed',
}

export type StatusList = keyof typeof RTMStatusEnum;

export interface RTMPatientRecord {
    id: number;
    firstName: string;
    lastName: string;
}

export interface RTMCycle {
    id: number;
    startDate: string;
    endDate: string;
    status: keyof typeof RTMStatusEnum;
    numActiveDays: number;
    numRequiredDays: number;
    numRemainingDays: number;
    patientRecord: RTMPatientRecord;
    pdfKey: string | null;
}

export type RTMCycles = RTMCycle[];

export interface RTMCycleEvent {
    id: number;
    type: RtmEvent;
    dateTime: string;
    minutes?: number;
    patientInteractionType?: InteractiveCommunicationEnum;
}

export interface RTMMonthCycle extends Omit<RTMCycle, 'patientRecord'> {
    code: RtmCode;
    events: RTMCycleEvent[];
}

export interface RTMItem {
    id: number;
    patientRecord: RTMPatientRecord;
    yearMonth: string;
    status: RtmCycleStatus;
    cycles: RTMMonthCycle[];
    pdfKey: string;
}

export interface RTMPdfUrlDto {
    pdfUrl: string;
}

export interface RTMPagedRequestParams extends PagedRequestParams {
    status?: StatusList[];
    start?: string;
    end?: string;
    locationId?: string;
}

export interface PatientPagedRequestParams extends PagedRequestParams {
    status?: `${PATIENT_STATUS}`;
}

export enum PromotionStatusEnum {
    ADVANCE = 'ADVANCE',
    REVERT = 'REVERT',
}

export interface UpdateRTMCycle {
    id: number;
    promotionStatus: PromotionStatusEnum;
}

export enum RTMFilterMonths {
    jan = 'Jan',
    feb = 'Feb',
    mar = 'Mar',
    apr = 'Apr',
    may = 'May',
    jun = 'Jun',
    jul = 'Jul',
    aug = 'Aug',
    sep = 'Sep',
    oct = 'Oct',
    nov = 'Nov',
    dec = 'Dec',
}

export type RTMFilterMonthsList = keyof typeof RTMFilterMonths;

export enum TemplateOrHEP {
    TEMPLATE = 'TEMPLATE',
    Program = 'Program',
}

export enum AssignOrCancelEnum {
    ASSIGN = 'ASSIGN',
    CANCEL = 'CANCEL',
}

// Provider Dashboard Actions
export type DashboardStatusUnion = `${DashboardActionStatus}`;

export interface DashboardPatient {
    id: number;
    firstName: string;
    lastName: string;
    status?: DashboardStatusUnion;
    mostRecentSession?: string;
}

export type DashboardPatients = {
    [key in DashboardActions]: DashboardPatient[];
};

export interface DashboardData extends DashboardPatients {
    TOTAL_NUM_PATIENTS: number;
}

/**
 * Slider Marks
 *
 * @interface CreateMarksInterface
 * @prop {number} total: The total number of marks
 * @prop {number} steps: The interval to show labels on besides 1st and last. If you don't need any set steps to 0
 * @proStyleTip If you ain't first you're last
 */
export interface CreateMarksInterface {
    total: number;
    steps: number;
    additionalLabel?: string;
}

export interface PROLabels {
    proQuestionText: string;
    proLabel: string;
}

export interface Choice {
    value: string;
    label: string;
}

export interface ReconcileResponse {
    reconciledCount: number;
}

export interface OrganizationLogoResponse {
    logoUrl: string;
    webLogoUrl: string;
}

export enum InteractiveCommunicationEnum {
    PATIENT_INT_COMM = 'PATIENT_INT_COMM',
}

// Patient Interactions
export interface PatientInteraction {
    id: number;
    patient: {
        id: number;
        firstName: string;
        lastName: string;
    };
    minutes: number;
    date: string;
    notes: string;
    interactionType: `${InteractiveCommunicationEnum}` | null;
    createdBy: {
        id: number;
        firstName: string;
        lastName: string;
    };
    updatedBy: {
        id: number;
        firstName: string;
        lastName: string;
    };
    dateCreated: string;
    dateUpdated: string;
    rtmCycleStatus: `${RtmCycleStatus}`;
}

export type PatientInteractionList = PatientInteraction[];

export interface Interaction {
    notes: string;
    minutes: number;
    date: string | DateTime;
    interactionType: `${InteractiveCommunicationEnum}` | null;
}

export type YearMonthFormat = `${number}-${string}`; // e. g. 2023-08
export type YearMonthDayFormat = `${number}-${string}-${string}`; // e. g. 2023-08-21

export interface PROTypeformForm {
    id: string;
    hasExerWebhookEnabled: boolean;
}

export interface PROTypeformResponse {
    form: { id: string; title: string | null };
    userId: string | undefined;
    location: string | undefined;
    dateTime: string;
}

export type CreatePROTypeformFormDto = Omit<PROTypeformForm, 'hasExerWebhookEnabled'>;
export interface AdminGaitSession {
    id: number;
    sessionUUID: string;
    sessionSchema: string;
    capturedDate: string;
    patient: {
        firstName: string;
        lastName: string;
    };
}

// Scan
export interface ScanSessionAdmin {
    id: number;
    createdDate: string;
    updatedDate: string;
    sessionUUID: string;
    session?: ScanSessionJSON;
    location?: { id: number; name: string };
    practitioner?: { id: number; firstName: string; lastName: string };
    organization?: { id: number; name: string };
    artifacts: string[];
    participant: Nullable<{
        archivedDate?: string;
        uid: Nullable<string>;
        isArchived: boolean;
        id: number;
        locationId: number;
    }>;
}

export interface ScanSessionAdminRow {
    id: number;
    createdDate: string;
    updatedDate: string;
    sessionUUID: string;
    locationName: string;
    practitionerName: string;
    organizationName: string;
    session?: ScanSessionJSON;
    action: { sessionUUID: string; artifacts: string[] };
    participantDetails: {
        uid: Nullable<string>;
        archivedDate: Nullable<string>;
    };
}

interface ScanSessionData {
    results: ScanSessionResult[];
    assessmentId: string;
    resultsSchema: ScanResultsSchema;
}

interface ScanSessionResult {
    id: number;
    name: string;
    value: number;
    units: string;
}

interface ScanSessionMeta {
    [key: string]: string | number;
}

export interface ScanModuleAssessment {
    id: string;
    nameOverride: Nullable<string>;
}

export type Assessment = Omit<ScanModuleAssessment, 'nameOverride'> & {
    updated: string;
    name: string;
    versions: string[];
    enabledVersions: string[];
    purpose: string;
    setupDescription: string;
    subjectOrientation: string;
    supportsLeftRight: boolean;
    thumbnailUrl: string;
    environmentAnimationUrl: string;
    participantImageUrl: Nullable<string>;
    participantImageProviderAssistUrl: Nullable<string>;
    leftParticipantImageUrl: Nullable<string>;
    leftParticipantImageProviderAssistUrl: Nullable<string>;
    rightParticipantImageUrl: Nullable<string>;
    rightParticipantImageProviderAssistUrl: Nullable<string>;
    exerciseProperties: Nullable<string>;
};

export interface ScanModule {
    name: string;
    subtitle: Nullable<string>;
    imageName: Nullable<string>;
    imageUrl: Nullable<string>;
    isLocked: boolean;
    assessments: ScanModuleAssessment[];
}

export interface SortableScanAssessment extends Assessment {
    nameOverride: Nullable<string>;
}

export type SortableScanModule = Omit<ScanModule, 'assessments'> & {
    id: string;
    assessments: SortableScanAssessment[];
};

export interface ScanSessionJSON {
    data: ScanSessionData;

    meta: ScanSessionMeta;
}

export interface ClientVersion {
    versionId: string;
    updateRequired: boolean;
}

export type Nullable<T> = T | null;

export type Nullish<T> = T | null | undefined;

export type Maybe<T> = T | undefined;
