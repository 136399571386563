import { PROAnswerType, RtmCode, RtmCycleStatus, RtmEvent, SelfReportedEnum } from '@/common/const';
import {
    GaitSession,
    CreateUpdatePatientDto,
    Location,
    PatientRecord,
    UpdateProfile,
    Practitioner,
    UserProfile,
    HealthPatientRecord,
    OrganizationHEP,
    CreateHEPDto,
    RawSession,
    HEPAssignment,
    PatientPagedRequestParams,
    CreateOrganizationDto,
    HEPTemplate,
    RTMCycles,
    RTMPagedRequestParams,
    UpdateRTMCycle,
    PromotionStatusEnum,
    RTMCycle,
    DashboardData,
    CreateLocationDto,
    CreatePractitionerDto,
    GoalType,
    UserProfileWithInviteCode,
    GaitSessionReport,
    RTMItem,
    RTMPdfUrlDto,
    UpdatePractitionerDto,
    OrganizationLogoResponse,
    PROTypeformForm,
    CreatePROTypeformFormDto,
    AdminGaitSession,
    PROTypeformResponse,
    OrganizationAdmin,
    UpdateOrganizationAdminDto,
    UpdateOrganizationDto,
} from '@/common/types';
import { sleep } from '@/common/utils';
import { ActivityData } from '@/components/Health/PatientSessions/SessionsPDF/types';
import { activityData } from '@/components/Health/PatientSessions/SessionsReport/activityData';
import { exerciseMetrics } from '@/services/mocks/exercise-metrics';
import { exercises } from '@/services/mocks/exercises';
import { PaginatedResult, Result } from './HttpService';
import { IHttpService } from './IHttpService';
import {
    organization11,
    patientRecord1,
    patientRecord2,
    patientRecord3,
    healthPatientRecord1,
    healthPatientRecord2,
    healthPatientRecord3,
    organization11Admin,
    organization11New,
    assessment1,
} from './mockData';

export class HttpService implements IHttpService {
    getPatientRecords = async (params: PatientPagedRequestParams): Promise<PaginatedResult<PatientRecord[]>> => {
        await sleep(500);
        return {
            items: [patientRecord1, patientRecord2, patientRecord3],
            metadata: {
                totalItems: 3,
                itemCount: 3,
                itemsForPage: 10,
                totalPages: 1,
                currentPage: 1,
            },
        };
    };

    getPatientRecord = async (patientRecordId: number): Promise<Result<PatientRecord>> => {
        await sleep(500);
        return patientRecord1;
    };

    updatePatientRecord = async (
        patientRecordId: number,
        _: CreateUpdatePatientDto,
    ): Promise<Result<PatientRecord>> => {
        await sleep(500);
        return patientRecord1;
    };

    updatePatientRecords = async (
        patientRecordIds: number[],
        _: CreateUpdatePatientDto,
    ): Promise<Result<PatientRecord[]>> => {
        await sleep(500);
        return [patientRecord1];
    };

    deletePatientRecord = async (patientRecordId: number): Promise<Result<boolean>> => {
        return true;
    };

    getPatientGaitSessions = async (patientRecordId: string): Promise<Result<GaitSession[]>> => {
        await sleep(500);
        return [
            {
                sessionUUID: '26fd8d66-6d55-4a6c-a735-2efe47d23269',
                sessionSchema: 'GAIT-SA-2',
                session: {
                    data: [
                        {
                            item: 'speed',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'avgSpeed',
                                    value: 0.8,
                                    displayName: 'Gait Speed',
                                    unit: 'm/s',
                                },
                                {
                                    name: 'lapOneTime',
                                    value: 4.9,
                                    displayName: 'Lap 1 Time',
                                    unit: 's',
                                },
                                {
                                    name: 'lapTwoTime',
                                    value: 5.1,
                                    displayName: 'Lap 2 Time',
                                    unit: 's',
                                },
                                {
                                    name: 'avgTime',
                                    value: 5,
                                    displayName: 'Average Time',
                                    unit: 's',
                                },
                                {
                                    name: 'distance',
                                    value: 5,
                                    displayName: 'Distance',
                                    unit: 'm',
                                },
                            ],
                        },
                        {
                            item: 'hip',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 10,
                                    displayName: 'Right Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 10,
                                    displayName: 'Right Hip Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 16,
                                    displayName: 'Left Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 25,
                                    displayName: 'Left Hip Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 35,
                                    displayName: 'Difference of Avg Hip Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Hip Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'knee',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 25,
                                    displayName: 'Right Knee Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 25,
                                    displayName: 'Right Knee Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 25,
                                    displayName: 'Left Knee Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 25,
                                    displayName: 'Left Knee Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 35,
                                    displayName: 'Difference of Avg Knee Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Knee Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'arm',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 25,
                                    displayName: 'Right Arm Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 25,
                                    displayName: 'Right Arm Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 25,
                                    displayName: 'Left Arm Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 25,
                                    displayName: 'Left Arm Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 35,
                                    displayName: 'Difference of Avg Arm Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Arm Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'ankle',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightDorsiflexion',
                                    value: 20,
                                    displayName: 'Right Ankle Dorsiflexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightPlantarFlexion',
                                    value: 21,
                                    displayName: 'Right Ankle Plantar Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftDorsiflexion',
                                    value: 23,
                                    displayName: 'Left Ankle Dorsiflexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftPlantarFlexion',
                                    value: 19,
                                    displayName: 'Left Ankle Plantar Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 35,
                                    displayName: 'Difference of Avg Ankle Dorsiflexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Ankle Plantar Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'trunk',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxTrunkAngle',
                                    value: 10,
                                    displayName: 'Max Trunk Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'head',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxHeadFlexionAngle',
                                    value: 10,
                                    displayName: 'Max Head Flexion Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'step',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'leftStep',
                                    value: 0.15,
                                    displayName: 'Left Step Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'rightStep',
                                    value: 0.14,
                                    displayName: 'Right Step Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'stepDifference',
                                    value: 15,
                                    displayName: 'Difference of Avg Step Length',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'stride',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'leftStride',
                                    value: 0.15,
                                    displayName: 'Left Stride Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'rightStride',
                                    value: 0.14,
                                    displayName: 'Right Stride Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'strideDifference',
                                    value: 15,
                                    displayName: 'Difference of Avg Stride Length',
                                    unit: '%',
                                },
                            ],
                        },
                    ],
                    meta: {
                        capturedDate: '2022-07-18T19:54:59.851Z',
                        assistanceOption: 'CANE',
                    },
                },
            },
            {
                sessionUUID: '0cf65733-3ff0-4c74-8e40-f594066996ec',
                sessionSchema: 'GAIT-SA-1',
                session: {
                    data: [
                        {
                            item: 'speed',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'avgSpeed',
                                    value: 0.8,
                                    displayName: 'Gait Speed',
                                    unit: 'm/s',
                                },
                                {
                                    name: 'avgTime',
                                    value: 5.43,
                                    displayName: 'Average Time',
                                    unit: 's',
                                },
                            ],
                        },
                        {
                            item: 'trunk',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxTrunkAngle',
                                    value: 10,
                                    displayName: 'Max Trunk Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'hip',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'hipFlexionAngle',
                                    value: 19,
                                    displayName: 'Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'hipFlexionAngleYAxis',
                                    value: 22,
                                    displayName: 'Hip Flexion Y Axis Angle',
                                    unit: '°',
                                },
                            ],
                        },
                    ],
                    meta: {
                        capturedDate: '2022-07-17T19:54:59.851Z',
                        assistanceOption: 'NONE',
                    },
                },
            },
            {
                sessionUUID: '2f114737-3126-4ef4-a2de-c9303572dbd7',
                sessionSchema: 'GAIT-SA-1',
                session: {
                    data: [
                        {
                            item: 'speed',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'avgSpeed',
                                    value: 0.8,
                                    displayName: 'Gait Speed',
                                    unit: 'm/s',
                                },
                                {
                                    name: 'avgTime',
                                    value: 5.43,
                                    displayName: 'Average Time',
                                    unit: 's',
                                },
                            ],
                        },
                        {
                            item: 'trunk',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxTrunkAngle',
                                    value: 10,
                                    displayName: 'Max Trunk Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'hip',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'hipFlexionAngle',
                                    value: 19,
                                    displayName: 'Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'hipFlexionAngleYAxis',
                                    value: 22,
                                    displayName: 'Hip Flexion Y Axis Angle',
                                    unit: '°',
                                },
                            ],
                        },
                    ],
                    meta: {
                        capturedDate: '2022-07-17T19:54:59.851Z',
                        assistanceOption: 'NONE',
                    },
                },
            },
            {
                sessionUUID: '0b71c5cb-93c4-43ee-a4ca-94a60c91fa59',
                sessionSchema: 'GAIT-SA-2',
                session: {
                    data: [
                        {
                            item: 'speed',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'avgSpeed',
                                    value: 10.8,
                                    displayName: 'Gait Speed',
                                    unit: 'm/s',
                                },
                                {
                                    name: 'lapOneTime',
                                    value: 4.9,
                                    displayName: 'Lap 1 Time',
                                    unit: 's',
                                },
                                {
                                    name: 'lapTwoTime',
                                    value: 5.1,
                                    displayName: 'Lap 2 Time',
                                    unit: 's',
                                },
                                {
                                    name: 'avgTime',
                                    value: 5,
                                    displayName: 'Average Time',
                                    unit: 's',
                                },
                                {
                                    name: 'distance',
                                    value: 5,
                                    displayName: 'Distance',
                                    unit: 'm',
                                },
                            ],
                        },
                        {
                            item: 'hip',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 22,
                                    displayName: 'Right Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 20,
                                    displayName: 'Right Hip Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 24,
                                    displayName: 'Left Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 21,
                                    displayName: 'Left Hip Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 35,
                                    displayName: 'Difference of Avg Hip Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Hip Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'knee',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 21,
                                    displayName: 'Right Knee Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 29,
                                    displayName: 'Right Knee Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 23,
                                    displayName: 'Left Knee Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 23,
                                    displayName: 'Left Knee Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 35,
                                    displayName: 'Difference of Avg Knee Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Knee Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'arm',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 20,
                                    displayName: 'Right Arm Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 11,
                                    displayName: 'Right Arm Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 25,
                                    displayName: 'Left Arm Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 22,
                                    displayName: 'Left Arm Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 39,
                                    displayName: 'Difference of Avg Arm Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 47,
                                    displayName: 'Difference of Avg Arm Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'ankle',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightDorsiflexion',
                                    value: 21,
                                    displayName: 'Right Ankle Dorsiflexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightPlantarFlexion',
                                    value: 20,
                                    displayName: 'Right Ankle Plantar Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftDorsiflexion',
                                    value: 28,
                                    displayName: 'Left Ankle Dorsiflexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftPlantarFlexion',
                                    value: 25,
                                    displayName: 'Left Ankle Plantar Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 10,
                                    displayName: 'Difference of Avg Ankle Dorsiflexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 42,
                                    displayName: 'Difference of Avg Ankle Plantar Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'trunk',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxTrunkAngle',
                                    value: 10,
                                    displayName: 'Max Trunk Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'head',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxHeadFlexionAngle',
                                    value: 11,
                                    displayName: 'Max Head Flexion Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'step',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'leftStep',
                                    value: 0.15,
                                    displayName: 'Left Step Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'rightStep',
                                    value: 0.14,
                                    displayName: 'Right Step Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'stepDifference',
                                    value: 15,
                                    displayName: 'Difference of Avg Step Length',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'stride',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'leftStride',
                                    value: 0.15,
                                    displayName: 'Left Stride Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'rightStride',
                                    value: 0.17,
                                    displayName: 'Right Stride Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'strideDifference',
                                    value: 15,
                                    displayName: 'Difference of Avg Stride Length',
                                    unit: '%',
                                },
                            ],
                        },
                    ],
                    meta: {
                        capturedDate: '2022-07-18T19:54:59.851Z',
                        assistanceOption: 'NONE',
                    },
                },
            },
            {
                sessionUUID: '0b71c5cb-93c4-43ee-a4ca-91a60c91fa59',
                sessionSchema: 'GAIT-SA-2',
                session: {
                    data: [
                        {
                            item: 'speed',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'avgSpeed',
                                    value: 0.8,
                                    displayName: 'Gait Speed',
                                    unit: 'm/s',
                                },
                                {
                                    name: 'lapOneTime',
                                    value: 5.4,
                                    displayName: 'Lap 1 Time',
                                    unit: 's',
                                },
                                {
                                    name: 'lapTwoTime',
                                    value: 4.7,
                                    displayName: 'Lap 2 Time',
                                    unit: 's',
                                },
                                {
                                    name: 'avgTime',
                                    value: 4,
                                    displayName: 'Average Time',
                                    unit: 's',
                                },
                                {
                                    name: 'distance',
                                    value: 6,
                                    displayName: 'Distance',
                                    unit: 'm',
                                },
                            ],
                        },
                        {
                            item: 'hip',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 18,
                                    displayName: 'Right Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 24,
                                    displayName: 'Right Hip Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 20,
                                    displayName: 'Left Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 29,
                                    displayName: 'Left Hip Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 32,
                                    displayName: 'Difference of Avg Hip Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 44,
                                    displayName: 'Difference of Avg Hip Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'knee',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 22,
                                    displayName: 'Right Knee Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 27,
                                    displayName: 'Right Knee Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 26,
                                    displayName: 'Left Knee Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 21,
                                    displayName: 'Left Knee Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 30,
                                    displayName: 'Difference of Avg Knee Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 40,
                                    displayName: 'Difference of Avg Knee Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'arm',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightFlexion',
                                    value: 17,
                                    displayName: 'Right Arm Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightExtension',
                                    value: 14,
                                    displayName: 'Right Arm Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftFlexion',
                                    value: 18,
                                    displayName: 'Left Arm Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftExtension',
                                    value: 12,
                                    displayName: 'Left Arm Extension Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 43,
                                    displayName: 'Difference of Avg Arm Flexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 23,
                                    displayName: 'Difference of Avg Arm Extension Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'ankle',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'rightDorsiflexion',
                                    value: 20,
                                    displayName: 'Right Ankle Dorsiflexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'rightPlantarFlexion',
                                    value: 19,
                                    displayName: 'Right Ankle Plantar Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftDorsiflexion',
                                    value: 27,
                                    displayName: 'Left Ankle Dorsiflexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'leftPlantarFlexion',
                                    value: 22,
                                    displayName: 'Left Ankle Plantar Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'flexionDifference',
                                    value: 13,
                                    displayName: 'Difference of Avg Ankle Dorsiflexion Angle',
                                    unit: '%',
                                },
                                {
                                    name: 'extensionDifference',
                                    value: 40,
                                    displayName: 'Difference of Avg Ankle Plantar Angle',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'trunk',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxTrunkAngle',
                                    value: 8,
                                    displayName: 'Max Trunk Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'head',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxHeadFlexionAngle',
                                    value: 14,
                                    displayName: 'Max Head Flexion Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'step',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'leftStep',
                                    value: 0.19,
                                    displayName: 'Left Step Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'rightStep',
                                    value: 0.11,
                                    displayName: 'Right Step Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'stepDifference',
                                    value: 13,
                                    displayName: 'Difference of Avg Step Length',
                                    unit: '%',
                                },
                            ],
                        },
                        {
                            item: 'stride',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'leftStride',
                                    value: 0.19,
                                    displayName: 'Left Stride Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'rightStride',
                                    value: 0.16,
                                    displayName: 'Right Stride Length',
                                    unit: 'm',
                                },
                                {
                                    name: 'strideDifference',
                                    value: 12,
                                    displayName: 'Difference of Avg Stride Length',
                                    unit: '%',
                                },
                            ],
                        },
                    ],
                    meta: {
                        capturedDate: '2022-07-17T19:52:59.851Z',
                        assistanceOption: 'WALKER',
                    },
                },
            },
        ];
    };

    getGaitReport = async (_: string, __: string): Promise<GaitSessionReport[]> => {
        return [
            {
                sessionUUID: '0cf65733-3ff0-4c74-8e40-f594066996ec',
                sessionSchema: 'GAIT-SA-1',
                session: {
                    data: [
                        {
                            item: 'speed',
                            type: 'gait',
                            measures: [
                                {
                                    name: 'avgSpeed',
                                    value: 0.8,
                                    displayName: 'Gait Speed',
                                    unit: 'm/s',
                                },
                                {
                                    name: 'avgTime',
                                    value: 5.43,
                                    displayName: 'Average Time',
                                    unit: 's',
                                },
                            ],
                        },
                        {
                            item: 'trunk',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'maxTrunkAngle',
                                    value: 10,
                                    displayName: 'Max Trunk Angle',
                                    unit: '°',
                                },
                            ],
                        },
                        {
                            item: 'hip',
                            type: 'bodypart',
                            measures: [
                                {
                                    name: 'hipFlexionAngle',
                                    value: 19,
                                    displayName: 'Hip Flexion Angle',
                                    unit: '°',
                                },
                                {
                                    name: 'hipFlexionAngleYAxis',
                                    value: 22,
                                    displayName: 'Hip Flexion Y Axis Angle',
                                    unit: '°',
                                },
                            ],
                        },
                    ],
                    meta: {
                        capturedDate: '2022-07-17T19:54:59.851Z',
                        assistanceOption: 'NONE',
                    },
                },
                subject: { firstName: 'Wyatt', lastName: 'Earp', gaitUid: '007' },
            },
        ];
    };

    updateGaitSessionComment = async (sessionId: string, comment: string): Promise<Result<GaitSession>> => {
        await sleep(500);
        return {
            sessionUUID: '8ebccd9c-0776-4d69-9c5a-365acdbde521',
            sessionSchema: 'GAIT-SA-1',
            session: {
                data: [
                    {
                        item: 'speed',
                        type: 'gait',
                        measures: [
                            {
                                name: 'speed',
                                value: 0.9,
                                displayName: 'Gait Speed',
                                unit: 'm/s',
                            },
                        ],
                    },
                    {
                        item: 'time',
                        type: 'gait',
                        measures: [
                            {
                                name: 'time',
                                value: 9.12,
                                displayName: 'Time',
                                unit: 's',
                            },
                        ],
                    },
                    {
                        item: 'trunk',
                        type: 'bodypart',
                        measures: [
                            {
                                name: 'maxTrunkAngle',
                                value: 8,
                                displayName: 'Max Trunk Angle',
                                unit: '°',
                            },
                        ],
                    },
                    {
                        item: 'hip',
                        type: 'bodypart',
                        measures: [
                            {
                                name: 'hipFlexionAngle',
                                value: 25,
                                displayName: 'Hip Flexion Angle',
                                unit: '°',
                            },
                            {
                                name: 'hipFlexionAngleYAxis',
                                value: 28,
                                displayName: 'Hip Flexion Y Axis Angle',
                                unit: '°',
                            },
                        ],
                    },
                ],
                meta: {
                    comment: 'gait looks good. no falls',
                    capturedDate: '2022-05-24T06:26:56+00:00',
                    assistanceOption: 'NONE',
                },
            },
        };
    };

    addNewPatient = async (newPatient: CreateUpdatePatientDto): Promise<Result<PatientRecord>> => {
        await sleep(500);
        return patientRecord1;
    };

    getHealthPatientSessions = async (
        patientRecordId: number,
        includeSelfReported?: boolean,
    ): Promise<Result<RawSession[]>> => {
        sleep(500);
        return [
            {
                id: 2948,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 136,
                                minROM: 29,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-22T06:00:00.506Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 5,
                                repsComplete: 5,
                                completedDate: '2022-09-22T06:00:00.506Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-22T06:00:00.506Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 5,
                                repsComplete: 5,
                                completedDate: '2022-09-22T06:00:00.506Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 8,
                                completedDate: '2022-09-22T06:00:00.507Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 1,
                                completedDate: '2022-09-22T06:00:00.507Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 21,
                                completedDate: '2022-09-22T06:00:00.507Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 475,
                    hepName: 'Fixture Program 9/22/2022',
                    selfReported: SelfReportedEnum.FULL,
                },
                sessionUUID: '0ccb52804f0c46a58846b29f7f3f6e1a',
                createdDate: '2022-09-22T06:00:00.506Z',
                updatedDate: '2022-09-22T06:00:00.506Z',
            },
            {
                id: 2877,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 103,
                                minROM: 36,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 1,
                                repsComplete: 1,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 5,
                                repsComplete: 5,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 5,
                                repsComplete: 5,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 27,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 11,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 20,
                                completedDate: '2022-09-21T06:00:00.581Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 463,
                    hepName: 'Fixture Program 9/21/2022',
                    selfReported: SelfReportedEnum.PARTIAL,
                },
                sessionUUID: '97a911809ca74897bb440ece17d982f9',
                createdDate: '2022-09-21T06:00:00.581Z',
                updatedDate: '2022-09-21T06:00:00.581Z',
            },
            {
                id: 2784,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 37,
                                minROM: 1,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 5,
                                repsComplete: 5,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 8,
                                repsComplete: 8,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 7,
                                repsComplete: 7,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 26,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 9,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 28,
                                completedDate: '2022-09-20T06:00:00.526Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 454,
                    hepName: 'Fixture Program 9/20/2022',
                    selfReported: SelfReportedEnum.FULL,
                },
                sessionUUID: 'c8c9fea1961542599668332f4e252752',
                createdDate: '2022-09-20T06:00:00.526Z',
                updatedDate: '2022-09-20T06:00:00.526Z',
            },
            {
                id: 2750,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 11,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 53,
                                name: 'Ankle Pumps, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 11,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 53,
                                name: 'Ankle Pumps, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 11,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 53,
                                name: 'Ankle Pumps, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 42,
                                name: 'Quad Stretch, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 42,
                                name: 'Quad Stretch, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                timeComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 42,
                                name: 'Quad Stretch, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 84,
                                name: 'Bridge with Physioball (Bilateral), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 84,
                                name: 'Bridge with Physioball (Bilateral), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 84,
                                name: 'Bridge with Physioball (Bilateral), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 71,
                                name: 'Calf Raises, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 71,
                                name: 'Calf Raises, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 71,
                                name: 'Calf Raises, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 0,
                                minROM: 0,
                                skipped: false,
                                timeGoal: 30,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 206,
                                name: 'Hip Flexion - Standing (ROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 222,
                                name: 'Active Knee Flexion - Standing, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 222,
                                name: 'Active Knee Flexion - Standing, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T21:06:32.662Z',
                            },
                            exercise: {
                                id: 222,
                                name: 'Active Knee Flexion - Standing, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 433,
                    hepName: "Jake's All X",
                    selfReported: SelfReportedEnum.FULL,
                },
                sessionUUID: 'CCE100D2-BFDA-4312-8B9E-6CA9AA7511E8',
                createdDate: '2022-09-19T21:06:32.662Z',
                updatedDate: '2022-09-19T21:06:32.662Z',
            },
            {
                id: 2715,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 170,
                                minROM: 4,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 6,
                                repsComplete: 6,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 4,
                                repsComplete: 4,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 3,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 6,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 24,
                                completedDate: '2022-09-19T06:00:00.530Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 448,
                    hepName: 'Fixture Program 9/19/2022',
                    selfReported: SelfReportedEnum.NONE,
                },
                sessionUUID: '449abe2fb7504bc99788495cc8392e00',
                createdDate: '2022-09-19T06:00:00.530Z',
                updatedDate: '2022-09-19T06:00:00.530Z',
            },
            {
                id: 2713,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 127,
                                minROM: 51,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 1,
                                repsComplete: 1,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 6,
                                repsComplete: 6,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 4,
                                repsComplete: 4,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 15,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 13,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 3,
                                completedDate: '2022-09-18T06:00:00.804Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 447,
                    hepName: 'Fixture Program 9/18/2022',
                    selfReported: SelfReportedEnum.FULL,
                },
                sessionUUID: '4ad9812003074da1b612c7db8e193b9b',
                createdDate: '2022-09-18T06:00:00.804Z',
                updatedDate: '2022-09-18T06:00:00.804Z',
            },
            {
                id: 2711,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 125,
                                minROM: 88,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 3,
                                repsComplete: 3,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 3,
                                repsComplete: 3,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 3,
                                repsComplete: 3,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 21,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 4,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 20,
                                completedDate: '2022-09-17T06:00:00.462Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 446,
                    hepName: 'Fixture Program 9/17/2022',
                    selfReported: SelfReportedEnum.NONE,
                },
                sessionUUID: 'eebbf0cbf7c042a5aba301af93eff694',
                createdDate: '2022-09-17T06:00:00.462Z',
                updatedDate: '2022-09-17T06:00:00.462Z',
            },
            {
                id: 2695,
                session: {
                    sets: [
                        {
                            setInfo: {
                                errors: {},
                                maxROM: 134,
                                minROM: 40,
                                skipped: false,
                                repsProper: 0,
                                repsComplete: 0,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 228,
                                name: 'Knee Flexion/Extension (AAROM), Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 5,
                                repsComplete: 5,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 10,
                                repsComplete: 10,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                repsGoal: 10,
                                repsProper: 10,
                                repsComplete: 10,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 31,
                                name: 'Squat, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 10,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 2,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                        {
                            setInfo: {
                                errors: {},
                                skipped: false,
                                timeGoal: 30,
                                timeComplete: 16,
                                completedDate: '2022-09-16T06:00:00.517Z',
                            },
                            exercise: {
                                id: 128,
                                name: 'Active Hamstring Stretch - Supine, Side-Facing',
                            },
                        },
                    ],
                    type: 'HEPSession',
                    hepId: 438,
                    hepName: 'Fixture Program 9/16/2022',
                    selfReported: SelfReportedEnum.FULL,
                },
                sessionUUID: '40b2890ebd6a45cd8abb13f2ea6368e6',
                createdDate: '2022-09-16T06:00:00.516Z',
                updatedDate: '2022-09-16T06:00:00.516Z',
            },
        ];
    };

    getHealthHEP = async (hepId: number): Promise<Result<OrganizationHEP>> => {
        sleep(500);
        return {
            id: 2,
            name: 'Patient 1 Program',
            weeklyFrequency: 2,
            dailyFrequency: 1,
            patientCount: 1,
            dateCreated: new Date(),
            blocks: [
                {
                    exerciseId: 1,
                    numSets: 3,
                    setGoal: {
                        reps: 9,
                        goalType: GoalType.REPS,
                    },
                },
            ],
        };
    };

    getHealthPatientRecords = async (): Promise<PaginatedResult<HealthPatientRecord[]>> => {
        await sleep(500);
        return {
            items: [healthPatientRecord1, healthPatientRecord2, healthPatientRecord3],
            metadata: {
                totalItems: 3,
                itemCount: 3,
                itemsForPage: 10,
                totalPages: 1,
                currentPage: 1,
            },
        };
    };

    deleteHealthHEP = async (hepId: number): Promise<boolean> => {
        sleep(500);
        return true;
    };

    getHealthHEPAssignments = async (hepId: number): Promise<Result<HEPAssignment[]>> => {
        sleep(500);
        return [];
    };

    createHealthHEPAssignments = async (
        hepId: number,
        patientRecordIds: number[],
    ): Promise<Result<HEPAssignment[]>> => {
        sleep(500);
        return [];
    };

    createHealthHEP = async (hepDetails: CreateHEPDto): Promise<Result<OrganizationHEP>> => {
        await sleep(500);
        return {
            id: 1,
            name: 'Shoulder HEPPER',
            weeklyFrequency: 2,
            dailyFrequency: 1,
            patientCount: 3,
            dateCreated: new Date(),
            blocks: [
                {
                    exerciseId: 1,
                    numSets: 3,
                    setGoal: {
                        reps: 9,
                        goalType: GoalType.REPS,
                    },
                },
            ],
        };
    };

    replaceHealthHEP = async (hepId: number, hepDetails: CreateHEPDto): Promise<Result<OrganizationHEP>> => {
        await sleep(500);
        return {
            id: 1,
            name: 'Shoulder HEPPER',
            weeklyFrequency: 2,
            dailyFrequency: 1,
            patientCount: 3,
            dateCreated: new Date(),
            blocks: [
                {
                    exerciseId: 1,
                    numSets: 3,
                    setGoal: {
                        reps: 9,
                        goalType: GoalType.REPS,
                    },
                },
            ],
        };
    };

    createHEPTemplate = async (hepDetails: HEPTemplate): Promise<Result<HEPTemplate>> => {
        await sleep(500);
        return {
            id: 1,
            name: 'Shoulder HEPPER',
            weeklyFrequency: 2,
            dailyFrequency: 1,
            dateUpdated: new Date().toISOString(),
            blocks: [
                {
                    exerciseId: 1,
                    numSets: 3,
                    setGoal: {
                        reps: 9,
                        goalType: GoalType.REPS,
                    },
                },
            ],
        };
    };

    getHEPTemplates = async (): Promise<Result<HEPTemplate[]>> => {
        sleep(500);
        return [
            {
                id: 1,
                name: 'Shoulder Program',
                weeklyFrequency: 2,
                dailyFrequency: 1,
                dateUpdated: new Date().toISOString(),
                blocks: [
                    {
                        exerciseId: 1,
                        numSets: 3,
                        setGoal: {
                            reps: 9,
                            goalType: GoalType.REPS,
                        },
                    },
                ],
            },
            {
                id: 2,
                name: 'Patient 1 Program',
                weeklyFrequency: 2,
                dailyFrequency: 1,
                dateUpdated: new Date().toISOString(),
                blocks: [
                    {
                        exerciseId: 1,
                        numSets: 3,
                        setGoal: {
                            reps: 9,
                            goalType: GoalType.REPS,
                        },
                    },
                ],
            },
        ];
    };

    getHEPTemplate = async (hepId: number): Promise<Result<HEPTemplate>> => {
        sleep(500);
        return {
            id: 2,
            name: 'Patient 1 Program',
            weeklyFrequency: 2,
            dailyFrequency: 1,
            dateUpdated: new Date().toISOString(),
            blocks: [
                {
                    exerciseId: 1,
                    numSets: 3,
                    setGoal: {
                        reps: 9,
                        goalType: GoalType.REPS,
                    },
                },
            ],
        };
    };

    deleteHEPTemplate = async (templateId: number): Promise<Result<boolean>> => {
        sleep(500);
        return true;
    };

    deleteMultipleHEPTemplates = async (templateId: number[]): Promise<Result<boolean>> => {
        sleep(500);
        return true;
    };

    updateHEPTemplate = async (templateId: number, templateDetails: HEPTemplate): Promise<Result<HEPTemplate>> => {
        await sleep(500);
        return {
            id: 1,
            name: 'Shoulder HEPPER',
            weeklyFrequency: 2,
            dailyFrequency: 1,
            dateUpdated: new Date().toISOString(),
            blocks: [
                {
                    exerciseId: 1,
                    numSets: 3,
                    setGoal: {
                        reps: 9,
                        goalType: GoalType.REPS,
                    },
                },
            ],
        };
    };

    createHealthHEPAssignment = async (
        hepId: number,
        patientRecordId: number,
        shouldRetireHeps = false,
    ): Promise<Result<HealthPatientRecord>> => {
        await sleep(500);
        return healthPatientRecord1;
    };

    deleteHealthHEPAssignment = async (hepId: number, patientRecordId: number): Promise<Result<boolean>> => {
        await sleep(500);
        return true;
    };

    healthResendInvite = async (patientRecordId: number) => {
        await sleep(500);
        return true;
    };

    getLocations = async () => {
        await sleep(500);
        return [
            {
                id: 1,
                name: 'Fake Location 1',
            },
            {
                id: 2,
                name: 'Fake Location 2',
            },
            {
                id: 3,
                name: 'Fake Location 3',
            },
        ];
    };

    getUserProfileByCode = async (code: string) => {
        await sleep(500);
        return {
            id: 1,
            userId: 'xxId',
            firstName: 'Ted',
            lastName: 'Lasso',
            email: 'ted@exerai.com',
            locations: [],
        };
    };

    getCurrentUser = async () => {
        await sleep(500);
        return {
            id: 1,
            userId: 'xxId2',
            firstName: 'Ted',
            lastName: 'Lasso',
            email: 'ted@exerai.com',
            organization: organization11,
            location: undefined,
            locations: [],
        };
    };

    createLocation = async (_: CreateLocationDto) => {
        await sleep(500);
        return {
            id: 1,
            name: 'Fake Location 1',
        };
    };

    updateLocation = async (_: number, __: Partial<Result<Location>>) => {
        await sleep(500);
        return {
            id: 1,
            name: 'Fake Location 1',
        };
    };

    getPractitioners = async (): Promise<Practitioner[]> => {
        await sleep(500);
        return [
            {
                id: 1,
                firstName: 'Fake',
                lastName: 'Practitioner1',
                email: 'practitioner1@email.com',
                role: 'PORTAL_PRACTITIONER',
                locations: [],
            },
            {
                id: 2,
                firstName: 'Fake',
                lastName: 'Practitioner2',
                email: 'practitioner2@email.com',
                role: 'PORTAL_PRACTITIONER',
                locations: [],
            },
            {
                id: 3,
                firstName: 'Fake',
                lastName: 'Practitioner3',
                email: 'practitioner3@email.com',
                role: 'PORTAL_PRACTITIONER',
                locations: [],
            },
        ];
    };

    getCurrentPractitioner = async (): Promise<Result<Practitioner>> => {
        await sleep(500);
        return {
            id: 1,
            firstName: 'Fake',
            lastName: 'Practitioner1',
            email: 'practitioner1@email.com',
            role: 'PORTAL_PRACTITIONER',
            locations: [],
        };
    };

    createPractitioner = async (practitioner: CreatePractitionerDto): Promise<Result<Practitioner>> => {
        await sleep(500);
        return {
            id: 1,
            firstName: 'Fake',
            lastName: 'Practitioner1',
            email: 'practitioner1@email.com',
            role: 'PORTAL_PROVIDER_ADMIN',
            locations: [],
        };
    };

    updatePractitioner = async (
        practitionerId: number,
        practitioner: Partial<UpdatePractitionerDto>,
    ): Promise<Practitioner> => {
        await sleep(500);
        return {
            id: 1,
            firstName: 'Fake',
            lastName: 'Practitioner1',
            email: 'practitioner1@email.com',
            role: 'PORTAL_PROVIDER_ADMIN',
            locations: [],
        };
    };

    deletePractitioner = async (practitionerId: number): Promise<Result<boolean>> => {
        await sleep(500);
        return true;
    };

    practitionerResendInvite = async (practitionerId: number): Promise<Result<boolean>> => {
        await sleep(500);
        return true;
    };

    updateUserProfile = async (id: number, updateUserProfile: UpdateProfile): Promise<Result<UserProfile>> => {
        await sleep(500);
        return {
            id: 1,
            userId: 'fakeuserid',
            firstName: 'Ted',
            lastName: 'Lasso',
            email: 'ted@exerai.com',
            locations: [],
        };
    };

    completeSignUp = async (id: number, updateUserProfile: UpdateProfile) => {
        await sleep(500);
        return {
            id: 1,
            userId: 'fakeuserid',
            firstName: 'Ted',
            lastName: 'Lasso',
            email: 'ted@exerai.com',
            locations: [],
        };
    };

    uploadPatientCsv = async (file, product) => {
        await sleep(500);
        return 40;
    };

    uploadPatientReconciliationCsv = async (file) => {
        await sleep(500);
        return { reconciledCount: 2 };
    };

    getGaitTemplateCsv = async () => {
        await sleep(500);
        return;
    };

    getHealthTemplateCsv = async () => {
        await sleep(500);
        return;
    };

    getGaitReconcileTemplateCsv = async () => {
        await sleep(500);
        return;
    };

    getExerciseMetrics = async () => {
        await sleep(500);
        return exerciseMetrics;
    };

    getPublishedExercises = async () => {
        await sleep(500);
        return exercises;
    };

    getPROPatientMessages = async (_: number) => {
        await sleep(55);
        return {
            1: {
                id: 1,
                text: 'What?',
                isChartable: true,
                answers: [
                    {
                        dateTime: '2022-03-15T19:24:34Z',
                        value: '5',
                        proAnswerType: PROAnswerType.CHOICE,
                    },
                ],
            },
        };
    };

    getOrganizations = async () => {
        await sleep(500);
        return [organization11];
    };

    getOrganizationAdmin = async (_: number): Promise<Result<OrganizationAdmin>> => {
        return { ...organization11, scanModules: null };
    };

    createOrganizationAdmin = async (_: CreateOrganizationDto) => {
        await sleep(500);
        return organization11;
    };

    updateOrganizationAdmin = async (_: number, __: UpdateOrganizationAdminDto) => {
        await sleep(500);
        return organization11Admin;
    };

    updateOrganization = async (_: number, __: UpdateOrganizationDto) => {
        await sleep(500);
        return organization11New;
    };

    adminGetAssessments = async () => {
        await sleep(500);
        return [assessment1];
    };

    adminGetClientVersions = async () => {
        await sleep(500);
        return [
            {
                updateRequired: true,
                versionId: '1.12.1',
            },
            {
                updateRequired: false,
                versionId: '1.12.2',
            },
        ];
    };

    adminUpdateClientVersion = async () => {
        await sleep(500);
        return {
            updateRequired: false,
            versionId: '1.12.2',
        };
    };

    adminAddClientVersion = async () => {
        await sleep(500);
        return {
            updateRequired: false,
            versionId: '1.12.2',
        };
    };

    adminGetUserByUUID = async (uuid: string): Promise<Result<UserProfile>> => {
        await sleep(500);
        return {
            id: 1,
            userId: 'fakeuserid',
            firstName: 'Ted',
            lastName: 'Lasso',
            email: 'ted@exerai.com',
            locations: [],
        };
    };

    adminGetScanModulesOrganizationOverride = async (): Promise<Result<{ organizationId: number }>> => {
        await sleep(500);
        return { organizationId: 1 };
    };

    adminUpdateScanModulesOrganizationOverride = async (_: number): Promise<Result<boolean>> => {
        await sleep(500);
        return true;
    };

    adminGetUserWithInviteCodeByEmail = async (email: string): Promise<Result<UserProfileWithInviteCode>> => {
        await sleep(500);
        return {
            firstName: 'Ted',
            lastName: 'Lasso',
            email: 'ted@exerai.com',
            inviteCode: 'fake',
            hasAccount: false,
        };
    };

    adminGetPROTypeformForms = async (): Promise<Result<PROTypeformForm[]>> => {
        return [{ id: 'byDrt', hasExerWebhookEnabled: true }];
    };

    adminGetPROTypeformUnprocessedResponses = async (): Promise<Result<PROTypeformResponse[]>> => {
        return [
            {
                userId: '2813308004',
                form: { id: '', title: 'Fall Report' },
                location: 'Denver',
                dateTime: new Date().toISOString(),
            },
        ];
    };

    adminCreatePROTypeformForm = async (_: CreatePROTypeformFormDto): Promise<Result<PROTypeformForm>> => {
        return { id: 'pol3der', hasExerWebhookEnabled: true };
    };

    getFavoriteExercises = async (): Promise<Result<string[]>> => {
        return [];
    };

    addFavoriteExercise = async (_: string): Promise<Result<string[]>> => {
        return ['C-0011'];
    };

    removeFavoriteExercise = async (_: string): Promise<Result<boolean>> => {
        return true;
    };

    getRTMCycles = async (params: RTMPagedRequestParams): Promise<PaginatedResult<RTMCycles>> => {
        await sleep(500);
        if (params.page === 1) {
            return {
                items: [
                    {
                        id: 1,
                        startDate: '2022-09-05T14:48:00.000Z',
                        endDate: '2022-10-03T14:48:00.000Z',
                        status: RtmCycleStatus.READY_TO_BILL,
                        numActiveDays: 5,
                        numRequiredDays: 16,
                        numRemainingDays: 7,
                        patientRecord: {
                            id: 2948,
                            firstName: 'Bob',
                            lastName: 'Belcher',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 142,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.ACTIVE_DAYS_INCOMPLETE,
                        numActiveDays: 5,
                        numRequiredDays: 16,
                        numRemainingDays: 6,
                        patientRecord: {
                            id: 763,
                            firstName: 'Han',
                            lastName: 'Solo',
                        },
                        pdfKey: null,
                    },
                    {
                        id: 112,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.IN_PROGRESS,
                        numActiveDays: 5,
                        numRemainingDays: 7,
                        numRequiredDays: 16,
                        patientRecord: {
                            id: 663,
                            firstName: 'Bilbo',
                            lastName: 'Baggins',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 13,
                        startDate: '2022-07-05T14:48:00.000Z',
                        endDate: '2022-08-05T14:48:00.000Z',
                        status: RtmCycleStatus.READY_TO_BILL,
                        numActiveDays: 16,
                        numRequiredDays: 16,
                        numRemainingDays: 4,
                        patientRecord: {
                            id: 56231,
                            firstName: 'Jimmy',
                            lastName: 'Pesto',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 643,
                        startDate: '2022-09-14T14:48:00.000Z',
                        endDate: '2022-09-29T14:48:00.000Z',
                        status: 'BILLED',
                        numActiveDays: 20,
                        numRequiredDays: 16,
                        numRemainingDays: 2,
                        patientRecord: {
                            id: 542,
                            firstName: 'Miss',
                            lastName: 'LaBonz',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 623,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.IN_PROGRESS,
                        numActiveDays: 3,
                        numRequiredDays: 16,
                        numRemainingDays: 9,
                        patientRecord: {
                            id: 2323,
                            firstName: 'Calvin',
                            lastName: 'Fischoeder',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 987,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.ACTIVE_DAYS_INCOMPLETE,
                        pdfKey: null,
                        numActiveDays: 20,
                        numRequiredDays: 16,
                        numRemainingDays: 1,
                        patientRecord: {
                            id: 4643,
                            firstName: 'Sergeant',
                            lastName: 'Bosco',
                        },
                    },
                ],
                metadata: {
                    totalItems: 7,
                    itemCount: 7,
                    itemsForPage: 10,
                    totalPages: 2,
                    currentPage: 1,
                },
            };
        } else if (params.page === 2) {
            return {
                items: [
                    {
                        id: 1,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.IN_PROGRESS,
                        numActiveDays: 3,
                        numRequiredDays: 16,
                        numRemainingDays: 9,
                        patientRecord: {
                            id: 1,
                            firstName: 'Calvin',
                            lastName: 'Fischoeder',
                        },
                        pdfKey: null,
                    },
                    {
                        id: 827,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.ACTIVE_DAYS_INCOMPLETE,
                        numActiveDays: 20,
                        numRequiredDays: 16,
                        numRemainingDays: 1,
                        patientRecord: {
                            id: 1,
                            firstName: 'Sergeant',
                            lastName: 'Bosco',
                        },
                        pdfKey: null,
                    },
                ],
                metadata: {
                    totalItems: 2,
                    itemCount: 2,
                    itemsForPage: 10,
                    totalPages: 2,
                    currentPage: 2,
                },
            };
        } else {
            return {
                items: [
                    {
                        id: 9128,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.IN_PROGRESS,
                        numActiveDays: 5,
                        numRequiredDays: 16,
                        numRemainingDays: 7,
                        patientRecord: {
                            id: 2948,
                            firstName: 'Bob',
                            lastName: 'Belcher',
                        },
                        pdfKey: null,
                    },
                    {
                        id: 142,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.ACTIVE_DAYS_INCOMPLETE,
                        numActiveDays: 5,
                        numRequiredDays: 16,
                        numRemainingDays: 6,
                        patientRecord: {
                            id: 5434,
                            firstName: 'Han',
                            lastName: 'Solo',
                        },
                        pdfKey: null,
                    },
                    {
                        id: 112,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.IN_PROGRESS,
                        numActiveDays: 5,
                        numRemainingDays: 7,
                        numRequiredDays: 16,
                        patientRecord: {
                            id: 42133,
                            firstName: 'Bilbo',
                            lastName: 'Baggins',
                        },
                        pdfKey: null,
                    },
                    {
                        id: 13,
                        startDate: '2022-07-05T14:48:00.000Z',
                        endDate: '2022-08-05T14:48:00.000Z',
                        status: RtmCycleStatus.READY_TO_BILL,
                        numActiveDays: 16,
                        numRequiredDays: 16,
                        numRemainingDays: 4,
                        patientRecord: {
                            id: 3223,
                            firstName: 'Jimmy',
                            lastName: 'Pesto',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 341,
                        startDate: '2022-09-14T14:48:00.000Z',
                        endDate: '2022-09-29T14:48:00.000Z',
                        status: 'BILLED',
                        numActiveDays: 20,
                        numRequiredDays: 16,
                        numRemainingDays: 2,
                        patientRecord: {
                            id: 13243211,
                            firstName: 'Miss',
                            lastName: 'LaBonz',
                        },
                        pdfKey: 'pdf',
                    },
                    {
                        id: 75,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.IN_PROGRESS,
                        numActiveDays: 3,
                        numRequiredDays: 16,
                        numRemainingDays: 9,
                        patientRecord: {
                            id: 1324,
                            firstName: 'Calvin',
                            lastName: 'Fischoeder',
                        },
                        pdfKey: null,
                    },
                    {
                        id: 325,
                        startDate: '2022-08-05T14:48:00.000Z',
                        endDate: '2022-09-05T14:48:00.000Z',
                        status: RtmCycleStatus.ACTIVE_DAYS_INCOMPLETE,
                        numActiveDays: 20,
                        numRequiredDays: 16,
                        numRemainingDays: 1,
                        patientRecord: {
                            id: 4324,
                            firstName: 'Sergeant',
                            lastName: 'Bosco',
                        },
                        pdfKey: null,
                    },
                ],
                metadata: {
                    totalItems: 7,
                    itemCount: 7,
                    itemsForPage: 10,
                    totalPages: 2,
                    currentPage: 1,
                },
            };
        }
    };

    getRTMCyclesByMonth = async (params: RTMPagedRequestParams): Promise<PaginatedResult<RTMItem[]>> => {
        await sleep(500);
        return {
            items: [
                {
                    id: 23,
                    patientRecord: {
                        id: 0,
                        firstName: 'Bob',
                        lastName: 'Belcher',
                    },
                    yearMonth: '2023-07',
                    status: RtmCycleStatus.BILLED,
                    pdfKey: 'pdf',
                    cycles: [
                        {
                            id: 4,
                            code: RtmCode.PATIENT_INTERACTION,
                            startDate: 'Thur Jul 01 2023',
                            endDate: 'Fri Jul 02 2023',
                            status: 'READY_TO_BILL',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Fri Jul 02 2023',
                                    minutes: 21,
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.INITIAL_HEP_ASSIGNMENT,
                                    dateTime: 'Thur Jul 01 2023',
                                },
                                {
                                    id: 3,
                                    type: RtmEvent.HEP_SESSION,
                                    dateTime: 'Wed Jul 01 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                        {
                            id: 43,
                            code: RtmCode.PATIENT_SETUP,
                            startDate: 'Tue Jun 29 2023',
                            endDate: 'Wed Jun 30 2023',
                            status: 'ACTIVE_DAYS_INCOMPLETE',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Tue Jun 29 2023',
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.PRO_RESPONSE,
                                    dateTime: 'Wed Jun 30 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                    ],
                },
                {
                    id: 21,
                    patientRecord: {
                        id: 1,
                        firstName: 'Tina',
                        lastName: 'Belcher',
                    },
                    yearMonth: '2023-07',
                    status: RtmCycleStatus.READY_TO_BILL,
                    pdfKey: 'pdf',
                    cycles: [
                        {
                            id: 4,
                            code: RtmCode.ADDITIONAL_PATIENT_INTERACTION,
                            startDate: 'Thur Jul 01 2023',
                            endDate: 'Fri Jul 02 2023',
                            status: 'BILLED',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Fri Jul 02 2023',
                                    minutes: 21,
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.PRO_RESPONSE,
                                    dateTime: 'Thur Jul 01 2023',
                                },
                                {
                                    id: 3,
                                    type: RtmEvent.PRO_RESPONSE,
                                    dateTime: 'Wed Jul 01 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                        {
                            id: 43,
                            code: RtmCode.DEVICE_AND_DATA_TRANSMISSION,
                            startDate: 'Tue Jun 29 2023',
                            endDate: 'Wed Jun 30 2023',
                            status: 'ACTIVE_DAYS_INCOMPLETE',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.INITIAL_HEP_ASSIGNMENT,
                                    dateTime: 'Tue Jun 29 2023',
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.PRO_RESPONSE,
                                    dateTime: 'Wed Jun 30 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                    ],
                },
                {
                    id: 36,
                    patientRecord: {
                        id: 0,
                        firstName: 'Louis',
                        lastName: 'Belcher',
                    },
                    yearMonth: '2023-07',
                    status: RtmCycleStatus.IN_PROGRESS,
                    pdfKey: 'pdf',
                    cycles: [
                        {
                            id: 4,
                            code: RtmCode.PATIENT_INTERACTION,
                            startDate: 'Thur Jul 01 2023',
                            endDate: 'Fri Jul 02 2023',
                            status: 'READY_TO_BILL',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Fri Jul 02 2023',
                                    minutes: 21,
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.INITIAL_HEP_ASSIGNMENT,
                                    dateTime: 'Thur Jul 01 2023',
                                },
                                {
                                    id: 3,
                                    type: RtmEvent.HEP_SESSION,
                                    dateTime: 'Wed Jul 01 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                        {
                            id: 43,
                            code: RtmCode.PATIENT_SETUP,
                            startDate: 'Tue Jun 29 2023',
                            endDate: 'Wed Jun 30 2023',
                            status: 'ACTIVE_DAYS_INCOMPLETE',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Tue Jun 29 2023',
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.PRO_RESPONSE,
                                    dateTime: 'Wed Jun 30 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                    ],
                },
                {
                    id: 65,
                    patientRecord: {
                        id: 0,
                        firstName: 'Gene',
                        lastName: 'Belcher',
                    },
                    yearMonth: '2023-07',
                    status: RtmCycleStatus.ACTIVE_DAYS_INCOMPLETE,
                    pdfKey: 'pdf',
                    cycles: [
                        {
                            id: 4,
                            code: RtmCode.PATIENT_INTERACTION,
                            startDate: 'Thur Jul 01 2023',
                            endDate: 'Fri Jul 02 2023',
                            status: 'READY_TO_BILL',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Fri Jul 02 2023',
                                    minutes: 21,
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.INITIAL_HEP_ASSIGNMENT,
                                    dateTime: 'Thur Jul 01 2023',
                                },
                                {
                                    id: 3,
                                    type: RtmEvent.HEP_SESSION,
                                    dateTime: 'Wed Jul 01 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                        {
                            id: 43,
                            code: RtmCode.PATIENT_SETUP,
                            startDate: 'Tue Jun 29 2023',
                            endDate: 'Wed Jun 30 2023',
                            status: 'ACTIVE_DAYS_INCOMPLETE',
                            pdfKey: null,
                            events: [
                                {
                                    id: 1,
                                    type: RtmEvent.PATIENT_INTERACTION,
                                    dateTime: 'Tue Jun 29 2023',
                                },
                                {
                                    id: 2,
                                    type: RtmEvent.PRO_RESPONSE,
                                    dateTime: 'Wed Jun 30 2023',
                                    minutes: 24,
                                },
                            ],
                            numActiveDays: 4,
                            numRequiredDays: 4,
                            numRemainingDays: 0,
                        },
                    ],
                },
            ],
            metadata: {
                totalItems: 7,
                itemCount: 7,
                itemsForPage: 10,
                totalPages: 2,
                currentPage: 1,
            },
        };
    };

    updateRTMCycle = async (params: UpdateRTMCycle): Promise<Result<RTMCycle>> => {
        const { promotionStatus } = params;
        await sleep(500);
        if (promotionStatus === PromotionStatusEnum.ADVANCE) {
            return {
                id: 1,
                startDate: '2022-08-05T14:48:00.000Z',
                endDate: '2022-09-05T14:48:00.000Z',
                status: 'BILLED',
                numActiveDays: 5,
                numRequiredDays: 16,
                numRemainingDays: 7,
                pdfKey: 'pdf',
                patientRecord: {
                    id: 2948,
                    firstName: 'Bob',
                    lastName: 'Belcher',
                },
            };
        } else if (promotionStatus === PromotionStatusEnum.REVERT) {
            return {
                id: 1,
                startDate: '2022-08-05T14:48:00.000Z',
                endDate: '2022-09-05T14:48:00.000Z',
                status: RtmCycleStatus.READY_TO_BILL,
                numActiveDays: 5,
                numRequiredDays: 16,
                numRemainingDays: 7,
                pdfKey: 'pdf',
                patientRecord: {
                    id: 2948,
                    firstName: 'Bob',
                    lastName: 'Belcher',
                },
            };
        } else {
            return { message: 'there was an error', status: 500 };
        }
    };

    getPatientActivitySummary = async (_: number, __: string, ___: string): Promise<Result<ActivityData>> => {
        await sleep(550);
        return activityData;
    };

    getMonthlyPatientActivityPdf = async (_: number): Promise<Result<RTMPdfUrlDto>> => {
        await sleep(550);
        return { pdfUrl: 'https://url.pdf' };
    };

    getPatientActivityPdf = async (_: number): Promise<Result<RTMPdfUrlDto>> => {
        await sleep(550);
        return { pdfUrl: 'https://url.pdf' };
    };

    uploadOrgLogo = async (organizationId: number, file: File): Promise<Result<OrganizationLogoResponse>> => {
        await sleep(550);
        return { logoUrl: 'https://placehold.co/400', webLogoUrl: 'https://placehold.co/400' };
    };

    uploadAdminOrgLogo = async (organizationId: number, file: File): Promise<Result<OrganizationLogoResponse>> => {
        await sleep(550);
        return { logoUrl: 'https://placehold.co/400', webLogoUrl: 'https://placehold.co/400' };
    };

    deleteOrgLogo = async (organizationId: string): Promise<Result<boolean>> => {
        await sleep(550);
        return true;
    };

    getProviderActions = async (): Promise<Result<DashboardData>> => {
        await sleep(500);
        return {
            NO_ACCOUNT: [
                {
                    id: 1,
                    firstName: 'Sarah',
                    lastName: 'Werkz',
                    status: 'Invite Sent',
                },
            ],
            NO_HEP: [
                {
                    id: 2,
                    firstName: 'Sarah',
                    lastName: 'Werkz',
                    status: 'Not Assigned',
                },
            ],
            NO_RECENT_SESSION: [
                {
                    id: 7,
                    firstName: 'Sarah',
                    lastName: 'Werkz',
                    mostRecentSession: '2022-08-09T09:56:99.876Z',
                },
            ],
            READY_TO_BILL: [
                {
                    id: 8,
                    firstName: 'Sarah',
                    lastName: 'Werkz',
                    status: 'Ready to Bill',
                },
            ],
            TOTAL_NUM_PATIENTS: 4,
        };
    };

    getAdminLocations = async () => {
        await sleep(500);
        return [
            {
                id: 1,
                name: 'Fake Location 1',
                organization: organization11,
            },
            {
                id: 2,
                name: 'Fake Location 2',
                organization: organization11,
            },
            {
                id: 3,
                name: 'Fake Location 3',
                organization: organization11,
            },
        ];
    };

    updateAdminLocationKeyword = async () => {
        await sleep(500);
        return {
            id: 3,
            name: 'Fake Location 3',
            organization: organization11,
        };
    };

    getScanSessions = async (_: string, __: string) => {
        return [
            {
                id: 1,
                createdDate: '2024-01-20T00:06:23.939Z',
                updatedDate: '2024-01-20T00:06:23.939Z',
                sessionUUID: 'ec321678-2169-439b-bae6-0285c4c056c6',
                artifacts: ['pose-log'],
                participant: null,
            },
        ];
    };

    getScanSessionPoseLogSignedUrl = async (sessionUUID: string): Promise<Result<string>> => {
        return 'url';
    };

    adminGetGaitSessions = async (_: string, __: string): Promise<Result<AdminGaitSession[]>> => {
        return [
            {
                id: 1,
                capturedDate: '2024-01-20T00:06:23.939Z',
                sessionUUID: 'ec321678-2169-439b-bae6-0285c4c056c6',
                sessionSchema: 'GAIT-SA-2',
                patient: { firstName: 'Tom', lastName: 'Jerrison' },
            },
        ];
    };

    adminGetGaitSessionPoseLogSignedUrl = async (_: string): Promise<Result<string>> => {
        return 'url';
    };

    newPlanOfCare = async (
        patientRecordId: number,
        data: {
            injuredSideOfBody: string | null;
            injuredBodyPart: string;
        },
    ): Promise<Result<boolean>> => {
        await sleep(500);

        return true;
    };

    dischargePatient = async (patientRecordId: number, data: string): Promise<Result<boolean>> => {
        await sleep(500);

        return true;
    };
}
