import React, { Dispatch, SetStateAction, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { DateTime } from 'luxon';
import { GaitSessionReport } from '@/common/types';
import { DownloadGaitReport } from './DownloadGaitReport';
import { GaitReportChart } from './GaitReportChart';
import { GaitReportParticipantTableContainer } from './GaitReportParticipantTableContainer';
import { GaitReportTabsWrapper } from './styles';

enum Feature {
    'CHART' = 'Chart',
    'CSV' = 'CSV',
    'PARTICIPANTS' = 'PARTICIPANTS',
}
interface GaitReportResultsProps {
    sessions: GaitSessionReport[];
    setSessions: Dispatch<SetStateAction<GaitSessionReport[] | undefined>>;
    startEnd: Date;
}
export const GaitReportResults = (props: GaitReportResultsProps) => {
    const { sessions, setSessions, startEnd } = props;
    const [feature, setFeature] = useState<Feature>(Feature.CHART);

    const handleChange = (_, f: Feature) => {
        setFeature(f);
    };

    const reset = () => {
        setSessions(undefined);
    };

    const handleReset = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        reset();
    };

    return (
        <>
            <h2 style={{ position: 'relative' }}>
                Gait Event: {`${DateTime.fromJSDate(new Date(startEnd)).toFormat('MM/dd/yyyy')}`}{' '}
                <span style={{ position: 'absolute', right: 0, fontSize: 14 }}>
                    <a href="#" onClick={handleReset}>
                        Generate new report
                    </a>
                </span>
            </h2>
            <Tabs value={feature} onChange={handleChange}>
                <Tab label={Feature.CHART} value={Feature.CHART} />
                <Tab label={Feature.CSV} value={Feature.CSV} />
                <Tab label={Feature.PARTICIPANTS} value={Feature.PARTICIPANTS} />
            </Tabs>
            <GaitReportTabsWrapper>
                {feature === Feature.CHART && <GaitReportChart sessions={sessions} startEnd={startEnd} />}
                {feature === Feature.CSV && <DownloadGaitReport sessions={sessions} startEnd={startEnd} />}
                {feature === Feature.PARTICIPANTS && <GaitReportParticipantTableContainer sessions={sessions} />}
            </GaitReportTabsWrapper>
        </>
    );
};
