import React, { useEffect, useState } from 'react';
import { GaitSessionReport } from '@/common/types';
import { GaitReportParticipantTable } from './GaitReportParticipantTable';
import { getSpeedFromSessionData } from './gaitReportUtils';

interface GaitReportParticipantTableContainerProps {
    sessions: GaitSessionReport[];
}

export interface GaitReportParticipantTableRow {
    SESSION_UUID: string;
    NAME: string;
    GAIT_UID: string;
    SPEED: string;
}

export const GaitReportParticipantTableContainer = (props: GaitReportParticipantTableContainerProps) => {
    const { sessions } = props;
    const [rows, setRows] = useState<GaitReportParticipantTableRow[]>([]);

    const columnStructure = [
        {
            Header: 'Name',
            accessor: 'NAME',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: 'Participant ID',
            accessor: 'GAIT_UID',
            Cell: ({ value }) => <div>{value}</div>,
        },
        {
            Header: 'Gait Speed (m/s)',
            accessor: 'SPEED',
            Cell: ({ value }) => <div>{value}</div>,
        },
    ];

    useEffect(() => {
        setRows(
            sessions.map((session) => {
                const speed = getSpeedFromSessionData(session);
                return {
                    SESSION_UUID: session.sessionUUID,
                    NAME: `${session.subject.firstName} ${session.subject.lastName}`,
                    GAIT_UID: session.subject.gaitUid,
                    SPEED: speed || '',
                };
            }),
        );
    }, [sessions]);

    return <GaitReportParticipantTable columnStructure={columnStructure} data={rows} />;
};
